import styled from 'styled-components'

export const Footer = styled.footer`
    padding-top: 50px;
    padding-bottom: 50px;
    background: #ffce4b;

    a {
        color: #222;
        text-decoration: none;
        cursor: pointer;
        font-weight: 900;
        border-bottom: 2px solid #fff;
        padding-bottom: 5px;
        transition: 0.15s;
        opacity: 0.75;
        display: inline-block;
      }
    
      a:hover {
        transform: translateY(-4px);
        opacity: 1;
      }
`

export const Nav = styled.nav`
    text-align: center;
    max-width: 60em;
    margin: 1em auto 2em;

    a {
        margin: 0 10px;
    }
`

export const About = styled.div`
    color: #222;
    text-align: center;
    font-size: 14px;
    font-weight: 200;
    margin: 1em auto 2em;
    padding: 1em;
    max-width: 60em;
`