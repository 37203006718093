import styled from 'styled-components'

export const Heading = styled.div`text-align: center; font-weight: 700; a { text-decoration: none; }`

export const Nav = styled.nav`
  flex: 1; display: flex; justify-content: flex-end; align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0 2vw;
  }
`

export const NavItem = styled.div`
  flex: none; align-items: center; display: flex; height: 100%; padding: 5px 1vw; border-right: 1px solid #eee;
  @media (max-width: 768px) {
    border: 0;
  }
`

export const Wrapper = styled.div`display: flex; width: 100%; border-bottom: 1px solid #eee; box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
a {
  color: #222;
}

@media (max-width: 768px) {
  display: block;
  width: 100%;
}

`
export const HeaderItem = styled.div`
border-right: 1px solid #eee; display: flex; align-items: center; padding: 1vh 2vw;

@media (max-width: 768px) {
  width: 100%;
}
`