import React from "react";
import { Link } from "gatsby"

import { Footer, Nav, About } from '../styles/footer'


export default function SiteFooter() {
  return (
    <Footer>
      <Nav>
        <Link to="/">Home</Link>
        &middot;
        <strong><a href="https://solomentalhealth.typeform.com/to/jyskPF" rel="noopener noreferrer" target="_BLANK">Suggest a resource</a></strong>
        &middot;
        <Link to="/about">About</Link>
      </Nav>

      <About>
        Made by &nbsp;
        <a href="https://jamiemchale.com">Jamie McHale</a>
        &nbsp; 🎉
      </About>
    </Footer>
  );
};
