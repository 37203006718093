import { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #fff
    color: #222;
    margin: 0;
    padding: 0;
  }
`

export default Global