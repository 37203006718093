import React from 'react'
import { Link } from "gatsby"
import logo from "../images/logo.png"

import { Wrapper, Heading, HeaderItem, Nav, NavItem } from '../styles/header'

const PageTitle = () => (
  <>
  <Wrapper>
    <HeaderItem>
      <div style={{ width: 75, height: 75, margin: '1vh 1vw'}}><Link to="/"><img src={logo} alt="Logo" /></Link></div>
      <Heading><Link to="/">Solo Mental Health</Link></Heading>
    </HeaderItem>
    <Nav>
      <NavItem><Link to="/">Home</Link></NavItem>
      <NavItem><Link to="/resources">Resources</Link></NavItem>
      <NavItem><Link to="/about">About</Link></NavItem>
      <NavItem><a href="https://twitter.com/healthsolo">Follow us</a></NavItem>
    </Nav>
    
  </Wrapper>
  </>
)

export default PageTitle
