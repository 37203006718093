import React, { Fragment } from 'react'

import PageTitle from './page-title'
import Meta from './meta'

export default function({ children }) {
    return (
        <Fragment>
            <Meta />
            <PageTitle />
            {children}
        </Fragment>
    )
}