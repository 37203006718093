import React from 'react'
import { Helmet } from "react-helmet"
import Global from '../styles/global'

export default () => (
  <div>
    <Helmet>
      <title>Solo Mental Health</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta charSet="utf-8"/>
    </Helmet>
    <Global />
  </div>
)
